export const prompts = [
    {
        title:"Ask out for coffee",
        prompt:"I am a guy who is matched with a girl on a dating app, write an opening flirty ice breaker which could grab her attention and would want to ask out to coffee date"
    },
    {
        title:"Ask out for movie",
        prompt:"I am a guy who is matched with a girl on a dating app, write an opening flirty ice breaker which could grab her attention and would want to ask out for movie"
    },
    {
        title:"Ask out for drive",
        prompt:"I am a guy who is matched with a girl on a dating app, write an opening flirty ice breaker which could grab her attention and would want to ask out for a long drive with music"
    },
    {
        title: "What made you Swipe Right on my profile?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on What made you Swipe Right on my profile?"
      },
      {
        title: "What does a day in the life of (name) look like?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on What does a day in the life of your life look like?"
      },
      {
        title: "Do you have any pets? Or want to own one in the future?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on Do you have any pets? Or want to own one in the future?"
      },
      {
        title: "Do you love your work?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on Do you love your work?"
      },
      {
        title: "What's your first impression of people?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on What’s your first impression of people?"
      },
      {
        title: "What does your typical weekend look like?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on What does your typical weekend look like?"
      },
      {
        title: "Next Trip",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on Where are you planning your next trip?"
      },
      {
        title: "When did your last relationship end?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on When did your last relationship end?"
      },
      {
        title: "Who’re you closer to? Your mom or dad?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on Who’re you closer to? Your mom or dad?"
      },
      {
        title: "What are some items on your bucket list?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on What are some items on your bucket list?"
      },
      {
        title: "What's your goal for the next 5 years?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on What’s your goal for the next 5 years?"
      },
      {
        title: "If you would choose to be a superhero, who would you be?",
        prompt: "I am a guy who is matched with a girl on a dating app, write an opening flirty follow up topic on If you would choose to be a superhero, who would you be?"
      },
      {
        title:"Write a love letter",
        prompt: `Write a short love letter to a girl, should not exceed 80 words. Try to impress her and write also write a shayari in Romanized Hindi, insert it somewhere in between the letter in the following format:

        [start]
        
        [text]
        
        [romanized hindi shayari]
        
        [text]
        
        [end]`
      },
      {
        title:"Write a proposal letter",
        prompt: `Write a short proposal letter to a girl. Persuade her to be your girlfriend, be flirty. Should not exceed 80 words. Try to impress her. Format:

        [intro]
        
        [text]
        
        [end with finesse]`
      }
]



export const shayari = [
  {
    "wp-block-quote": "मोहब्बत करना है, फिर से करना है,बार बार करना, हजार बार करना है,लेकिन सिर्फ तुम से ही करना है."
  },
  {
    "wp-block-quote": "मेरे दिल पर उसके प्यार का उधार रहता है,मेरी आंखों में उसके लिये प्यार बेशूमार रहता है,उसके बिना दिन का चैन गया और रातों की नींद गई,बस धड़कता इस दिल में वो दिलदार रहता है."
  },
  {
    "wp-block-quote": "चाहता हूँ उसका नाम लिख दूँ,अपनी हर शायरी के साथ,लेकिन फिर सोचता हूँ,बहुत भोली है मेरी जान,कहीं बदनाम न हो जाए."
  },
  {
    "wp-block-quote": "तेरे प्यार में एक नशा है,इसलिये ही ये दुनिया हमसे खफ़ा हैं,मत करना हमसे इतनी मोहब्बत,की तेरा दिल ही तुझसे पूंछे की तेरी धड़कन कहाँ है."
  },
  {
    "wp-block-quote": "नजरो से क्यों जलाती हो आग चाहत की,जलाकर क्यों बुझाती हो आग चाहत की,सर्द रातों में भी कराती हो तपन का एहसास,हवा देकर क्यों बढ़ाती हो आग चाहत की."
  },
  {
    "wp-block-quote": "मोहब्बत में जुदाई भी होती है,मोहबत मे तन्हाई भी होती हैं,मोहब्बत में बेवफाई भी होती है,तू ज़रा थाम कर तो देख हाथ मेरा,तब तू जानेगी मोहब्बत में सच्चाई भी होती है."
  },
  {
    "wp-block-quote": "अगर कभी उदास हो जाओ तो मेरे हँसी मांग लेना,अगर कभी कोई गम आपके पास आये तो मेरी ख़ुशी मांग लेना,खुदा आपको लम्बी उम्र दे जीने के लिए,अगर एक पल भी कम पड़े तो मेरी जिंदगी मांग लेना."
  },
  {
    "wp-block-quote": "इश्क के दरिया में डूब के पार उतर जाएंगे,एक दूजे की बाहों में आकर सवर जाएंगे,बसाये रखेंगे सदा एक दूजे को इस दिल में,जो कभी बिछड़े तो हम दोनों ही मर जाएंगे."
  },
  {
    "wp-block-quote": "आँखों के सामने हमने हर पल आपको ही पाया है,हमने तो हर पल इस दिल में बस आपको ही बसाया है,हम आपके बिना जिए भी तो कैसे,क्या कोई अपनी जान के बिना भी जी पाया है."
  },
  {
    "wp-block-quote": "सोचो उस पल दिल कितना मजबूर होता है,जब कोई किसी की यादो में चूर होता है,प्यार क्या है पता तब चलता है,जब कोई किसी की नज़रो से दूर होता है."
  },
  {
    "wp-block-quote": "हर एक हसीन चेहरे में गुमान उसका था,वस सका न इस दिल कोई क्योंकि ये मकान उसका था,मिट गया हर एक गम मेरे दिल से,लेकिन जो मिट न सका मेरे दिल से वो नाम उसका था."
  },
  {
    "wp-block-quote": "कहता है पल पल तुमसे हो कर दिल ये दिवाना,एक पल भी जाने जाना हमसे दूर नही जाना."
  },
  {
    "wp-block-quote": "मोहब्बत की शमा जला कर तो देखो,ये दिलो की दुनिया सज़ा कर तो देखो,तुझे हो न जाए मोहब्बत तो कहना,ज़रा हमसे नजरे मिला कर देखो."
  },
  {
    "wp-block-quote": "गिले शिकवे मेरे दिल से न लगा लेना,जो कभी रुठू तो मुझे मना लेना,जिंदगी का क्या पता कल हो न हो,लेकिन जब भी मिलूँ, मुझे गले से लगा लेना."
  },
  {
    "wp-block-quote": "अपनी मोहब्बत से सजाना है तुझको,कितनी चाहत है तुझसे ये बताना है तुझको,राहों में तेरी बिछाकर मोहब्बत अपनी,इश्क के सफर पर ले जाना है तुझको."
  },
  {
    "wp-block-quote": "दिल डूब कर रह जाता है तेरे इन आंखों के प्यालों में,ये दिल उलझ कर रह जाता है तेरे मासूम सवालों में,तुझसे बढ़ कर न कोई है और न कोई होगा,तू सबसे हसीन है सब हुस्न वालों में."
  },
  {
    "wp-block-quote": "एहसास के दामन में आंसू गिरा कर देखो,प्यार कितना है कभी हमे आज़मा कर देखो,बिछड़ कर तुमसे क्या होगी दिल की हालत,कभी किसी आईने पर पत्थर गिरा कर देखो."
  },
  {
    "wp-block-quote": "किसी को सिर्फ पा लेना मोहब्बत नही होती है,बल्कि किसी के दिल में जगह बना लेना मोहब्बत होती है."
  },
  {
    "wp-block-quote": "तुझ पर एतवार करना हैं,दिल जान से प्यार करना है,मेरी ख्वाइश ज्यादा नही बस इतनी हैं,तुझे हर लम्हे में अपना बना कर रखना है."
  },
  {
    "wp-block-quote": "करीब रहूं या दूर जाऊँ मैं,बस मेरा तो यही आलम है,तुझे हर वक्त चाहूं मैं."
  },
  {
    "wp-block-quote": "हम भी अब मोहब्बत के गीत गाने लगे हैं,जब से वो हमारे ख्वाबों में आने लगे हैं."
  },
  {
    "wp-block-quote": "मांग लुंगी तुझे अब तकदीर से,क्योंकि अब मेरा मन नही भरता है तेरी तस्वीर से."
  },
  {
    "wp-block-quote": "किसी मोड़ पर उसका दीदार हो जाये,काश उसे भी मुझ पर एतवार हो जाये,उसकी पलके झुकें और इकरार हो जाये,काश उसे भी मुझ से प्यार हो जाये."
  },
  {
    "wp-block-quote": "कभी कभी अपने सनम से ये दिल रुठ जाता है,फिर उसकी याद में ये दिल टूट जाता है,गलत फैमियों को जल्दी मिटाना ज़रूरी है,वरना ये रिश्ता हमेशा के लिये टूट जाता है."
  },
  {
    "wp-block-quote": "चुपके से आकर मेरे दिल में उतर जाते हो,सांसो में मेरी खुशबू बन कर बिखर जाते हो,कुछ यूँ चला है तेरे इश्क का जादू,सोतें जागते अब तो तुम ही तुम नज़र आते हो."
  },
  {
    "wp-block-quote": "हमने तेरी तस्वीर में वो रंग भरा है,की लोग देखेंगे तुझे और पूछेंगे मुझे."
  },
  {
    "wp-block-quote": "जिक्र करता है ये दिल सुबह शाम तेरा,बहते हैं आँसू और बनता है नाम तेरा,किसी और को क्यों देखे ये आँखे मेरी,जब दिल पर लिखा है मेरे नाम तेरा."
  },
  {
    "wp-block-quote": "तुम ही से डरते हैं, तुम्ही पे मरते हैं,तुम ही जिंदगी ही हमारी,तुम्ही से हम सबसे ज्यादा प्यार करते हैं."
  },
  {
    "wp-block-quote": "तुझको लेकर मेरा ख्याल नही बदलेगा,दिन बदलेंगे,साल बदलेगा,लेकिन दिल का हाल नही बदलेगा."
  },
  {
    "wp-block-quote": "तुम्हारे ही प्यार में ये मेरा जीवन चहकता है,तुम्हारे बिना ये मेरा पागल मन बहकता है,इस मेरे दिल की वेबफाई तो देखो,मेरा है पर तुम्हारे लिये धड़कता है."
  },
  {
    "wp-block-quote": "वहाँ मोहब्बत में पनाह मिले भी तो कैसे,जहाँ मोहब्बत बेपनाह हो."
  },
  {
    "wp-block-quote": "हर रात एक धुन गुनगुनाती है,हर फूल से महक आती है,हमारा ख्याल आपको आये या न आए,लेकिन हमे तो बस आपकी ही याद आती है."
  },
  {
    "wp-block-quote": "तेरा ख्याल भी है क्या गजब,जो न आये तो आफत,और जो आ जाए तो कयामत."
  },
  {
    "wp-block-quote": "किसी न किसी बहाने से आपको याद करते हैं,अपनी रूह में आपको हम महसूस करते हैं,इतनी बार तो आप सांस भी नही लेते होंगे,जितनी बार हम आपको याद करते हैं."
  },
  {
    "wp-block-quote": "तेरी मोहब्बत मैंने एक बात सीखी है,तेरे साथ के बगैर ये दुनिया फीकी है."
  },
  {
    "wp-block-quote": "जाने क्या मासूमियत है तेरे चहरे में,आमने सामने ज्यादा छुप छुप के देखने में मज़ा आता है."
  },
  {
    "wp-block-quote": "कुर्बान हो जाऊँ,उस दर्द पर जिसका इलाज सिर्फ तुम हो."
  },
  {
    "wp-block-quote": "किसी के लिये किसी की अहमयित खास होती है,और एक दिल की चाबी दूसरे के पास होती है."
  },
  {
    "wp-block-quote": "तुम सामने आये तो अजब तमाशा हुआ,हर शिकायत ने जैसे खुद ख़ुशी कर ली."
  },
  {
    "wp-block-quote": "अदा है, ख्वाब है, तकसीम है, तमाशा है,मेरी इन आंखों में एक शख्श बेतहाशा है."
  },
  {
    "wp-block-quote": "तुमसे मिले हैं जबसे, जी चाहता है,की अब बिछड़ जाएं सबसे."
  },
  {
    "wp-block-quote": "हाल तो पूछ लू तेरा, पर डरता हूँ,जब जब सुनता हूँ आवाज़,तेरी मोहब्बत सी हो जाती है."
  },
  {
    "wp-block-quote": "इश्क में धोखा खाने लगे हैं लोग,दिल की जगह जिस्म को चाहने लगे हैं लोग."
  },
  {
    "wp-block-quote": "उनका इतना सा किरदार है मेरे जीने में,कि उनका दिल धड़कता है मेरे सीने में."
  },
  {
    "wp-block-quote": "मोहब्बत की हद न देखना जनाब,साँसे खत्म हो सकती हैं, पर मोहब्बत नही."
  },
  {
    "wp-block-quote": "अगर खोजूँ तो कोई मुझे मिल ही जाएगा,लेकिन तुम्हारी तरह मुझे कौन चाहेगा."
  },
  {
    "wp-block-quote": "दुआओ में मांग हम चुकें है तुझे,कुबूल होने का इंतज़ार हमे उम्र भर रहेगा."
  },
  {
    "wp-block-quote": "काश मेरी जिंदगी में भी वो दिन आये,मैं खोलूँ अपनी आँखे और तू नज़र आये."
  },
  {
    "wp-block-quote": "मोहब्बत के बाजार में हुस्न वालों की ज़रूरत नही होती,जिस पर दिल आ जाए वही खास होता है."
  },
  {
    "wp-block-quote": "मेरा तुझसे लड़ना तो एक बहाना है,मुझे तो तेरे साथ सिर्फ वक्त बिताना है."
  },
  {
    "wp-block-quote": "आशिकी करने को दिल नही करता है अब,लेकिन तेरा चेहरा देखते ही दिल फिर आशिक हो जाता है."
  },
  {
    "wp-block-quote": "बिन तेरे मेरी हर ख़ुशी अधूरी है,फिर सोच मेरे लिए तू कितनी ज़रूरी है."
  },
  {
    "wp-block-quote": "ज़रूरी नही है, इश्क में बाहों के सहारे ही मिले,किसी को जी भर कर महसूस करना भी मोहब्बत है."
  },
  {
    "wp-block-quote": "कितना चाहता हूँ तुझे मुझे बताना नही आता,बस इतना जानता हूँ मुझे तेरे बिन रहना नही आता."
  },
  {
    "wp-block-quote": "कभी न पीने की कसम खा लुंगा,साथ जीने मरने की कसम कहा लुंगा,बस एक बार मुझको अपनी आंखों से पिला दे,शराफत से जीने की कसम खा लुंगा."
  },
  {
    "wp-block-quote": "मेरी इस दुनिया की ख़ुशी तुमसे है,मेरी इन आँखों की रौशनी तुमसे है,अब इससे ज़्यादा मैं तुमसे क्या कहूँ,मेरी हर साँस और मेरी जिंदगी तुमसे है."
  },
  {
    "wp-block-quote": "तेरे इश्क से मिली है मेरे बजूद को शोहरत,वरना मेरा ज़िक्र ही कहाँ था तेरी दास्तां से पहले."
  },
  {
    "wp-block-quote": "दिल में मोहब्बत हो तो दिलबर सुहाना लगता है,हो हाथो में हाथ हो तो सफर सुहाना लगता है,फुर्सत के कुछ पल चुरा कर जिंदगी से,जब वो घर में रहते हैं तो घर सुहाना लगता है."
  },
  {
    "wp-block-quote": "मदहोश मत करो मुझे अपना चेहरा दिखा कर,मोहब्बत अगर चेहरे से होती तो खुदा दिल नही बनाता."
  },
  {
    "wp-block-quote": "तेरे ही प्यार से मेरा ये दिल धड़कता है,तेरे ही नाम से मेरा ये दिल बहकता है,मेरे इस दिल की वेबफाई को तो देखो,मेरा है पर तेरे लिये ही धड़कता है."
  },
  {
    "wp-block-quote": "अगर एहसास है तो करलो मोहब्बत को महसूस,ये वो ज़ज़्बा है जो लवज़ो में बया नही होता है."
  },
  {
    "wp-block-quote": "बस तुम ही तुम हो अब तो मेरी निगाहों में,हमने तो निगाहें बिछा रखी हैं तेरी राहों में,जिंदगी भर की बेकरारी को करार आ जाये,अगर समेट लूँ कभी तुझे अपनी बाँहों में."
  },
  {
    "wp-block-quote": "इसका एहसास किसी को न होने देना,की तेरी चाहतों से चलती हैं साँसे मेरी."
  },
  {
    "wp-block-quote": "मैं वो नही जो तुझे गम में छोड़ दूँ,मै वो नही जो तुझसे नाता तोड़ दू,मैं वो हूँ जो तेरी साँसे रुके तो,अपनी साँसे छोड़ दूँ."
  },
  {
    "wp-block-quote": "मेरी हर अदा का आइना तुझसे से है,मेरी हर मंजिल का रास्ता तुझसे है,कभी दूर न होना मेरी जिंदगी से,मेरी हर ख़ुशी का वास्ता तुझसे है."
  },
  {
    "wp-block-quote": "कुछ सोचूँ तो तेरा ख्याल आता है,कुछ बोलूं तो तेरा नाम आता है,कब तक छुपाऊँ दिल की बात को,तेरी तो हर बात पर मुझे तो प्यार आता है."
  },
  {
    "wp-block-quote": "ख्वाबो में आकर दिल में उतर जाता हो,खुशबू बन कर सांसों में बिखर जाते हो,क्यों करते हो इश्क का जादू,अब तो हर तरफ तुम ही तुम नज़र आते हो."
  },
  {
    "wp-block-quote": "मैंने तो सिर्फ तुझसे मोहब्बत करने की दुआ मांगी है,मैंने तो सिर्फ हर दुआ में तेरी ही वफ़ा मांगी है,ये दुनिया लाख जले हमारी मोहब्बत से,लेकिन मैंने तो सिर्फ तुझसे मोहब्बत करने की सज़ा मांगी है."
  },
  {
    "wp-block-quote": "काश तुम पूंछो की हम तुम्हारे क्या लगते हैं,और हम तुम्हे गले लगा कर कहे सब कुछ."
  },
  {
    "wp-block-quote": "माना की बदल गये अंदाज़े मोहब्बत वक्त के साथ,लेकिन दिल चुराने का ज़रिया आज भी आँखे ही हैं."
  },
  {
    "wp-block-quote": "वो पूँछतें हैं हमसे की क्या हुआ,अब हम उन्हें कैसे बताएं,उन्ही से इश्क हुआ."
  },
  {
    "wp-block-quote": "बन्द आँखों में चले आते हो मेरी अपनों की तरह,और आंख खुलतें ही चले जाते हो सपनो की तरह."
  },
  {
    "wp-block-quote": "अपनी हाथो की उंगलियों को ज़रा सा दिल पर क्या रखा,तेरी यादो की धड़कन धड़कने लगी."
  },
  {
    "wp-block-quote": "न कर मेरी मोहब्बत पर शक पगली,अगर सबूत देने पर आया तो तू बदनाम हो जायेगी."
  },
  {
    "wp-block-quote": "इनकार भी करते हैं इकरार के लिए,नफरत भी करते हैं प्यार के लिए,उल्टी ही चाल चलते है प्यार करने बाले,आँखे बन्द करते हैं दीदार के लिए."
  },
  {
    "wp-block-quote": "मोहब्बत का कोई रंग नही फिर भी वो रंगीन है,मोहब्बत का कोई चहरा नही फिर भी वो हसीन है."
  },
  {
    "wp-block-quote": "कुछ नशा तो आपकी बात है,कुछ नशा तो ये धीमी बरसात का है,आप यूँ ही हमे शराबी न कहिए,क्योंकि कुछ नशा तो आपकी मुलाकात का है."
  },
  {
    "wp-block-quote": "हमको चाहते होंगे और भी बहुत लोग,लेकिन मुझे तो सिर्फ मोहब्बत अपनी मोहब्बत से है."
  },
  {
    "wp-block-quote": "दिल के रिश्ते का कोई नाम नही होता,हर रास्ते का कोई मुकाम नही होता,अगर निभाने की चाहत हो दोनों तरफ,कसम से कोई रिश्ता नाकाम नही होता."
  },
  {
    "wp-block-quote": "दिल का एहसास जानना है तो प्यार करके देखो,अपनी आँखों में किसी को उतार कर तो देखो,चोट उन्हें लगेगी दर्द तुम्हे होगा,जरा अपना दिल एक बार हार कर तो देखो."
  },
  {
    "wp-block-quote": "प्यार तो जिंदगी का अफसाना है,प्यार का तो अपना ही एक तराना है,सबको पता है की इश्क में सिर्फ मिलते आंसू ही हैं,फिर भी ये ज़माना इसका दीवाना है."
  },
  {
    "wp-block-quote": "मेरे दिल में तेरे लिए प्यार आज भी है,माना की तुझे मेरे प्यार पर शक आज भी है,नाव में बैठ कर धोये थे हाथ तालाब के पानी में,उस तालाब में तेरे हाथो की मेहँदी की खुशबू आज भी है."
  },
  {
    "wp-block-quote": "मैंने कहा जान है तू मेरी,मैंने कहा ज़िन्दगी है तू मेरी,कभी मुझसे जुदा होने की सोचना भी मत,क्योंकि पहचान है तू मेरी."
  },
  {
    "wp-block-quote": "मुझे तो न कोई आसमान चाहिये,मुझे तो न कोई जहाँ चाहिये,तू तो सितारों की एक महफ़िल है,बस उस पूरी महफ़िल में से बस एक तू चाहिए."
  },
  {
    "wp-block-quote": "अपनी जिंदगी के बस यही उसूल हैं,अगर तू कह तो काटें भी कुबूल हैं,हंस कर चल दूँ कांच के टुकड़ो पर भी,अगर तू कह ये मेरे बिछाये हुए फूल हैं."
  },
  {
    "wp-block-quote": "आग सूरज में होती है,पर जलना ज़मी को पड़ता है,मोहब्बत निगाहों से होती है,पर तड़पाना दिल को पड़ता है."
  },
  {
    "wp-block-quote": "इश्क के समंदर में सब डूबना चाहते हैं,इस्क में लोग कुछ खोते हैं तो कुछ पाते है,इश्क तो एक गुलाब है जो सब तोडना चाहते हैं,लेकिन हम तो ये गुलाब आपको देना चाहते हसीन."
  },
  {
    "wp-block-quote": "बस एक छोटी सी हां कर दो,और बस इस तरह मेरे नाम सारा जहाँ कर दो,देते हैं हम ये गुलाब आपको,बस अब ये अपनी मोहब्बत हमारे नाम कर दो."
  },
  {
    "wp-block-quote": "वो दिल ही क्या जो वफ़ा न करे,तुझे भूल कर जिए कभी खुदा न करे,रहेगी तेरी मोहब्बत जिंदगी बन कर,वो बात और है जिंदगी वफ़ा न करे."
  },
  {
    "wp-block-quote": "सूखे पत्तो से प्यार कर लेंगे हम,खुद पर फिर से ऐतबार कर लेंगे हम,सिर्फ एक बार कह दो तुम मेरे हो सनम,कसम से तेरा जिंदगी भर इंतज़ार कर लेंगे हम."
  }
 ]