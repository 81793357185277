import React from 'react'
import styled from '@emotion/styled'
import { prompts } from '../util/prompts'
import { Typography ,Badge} from '@mui/material'

const SuggestionBox = styled.div`
width:80%;
margin:auto;
`
function Suggestions({sendMessage}) {
  return (
    <SuggestionBox>
    <Typography variant="h6">Or try one of these:
    
    </Typography>
    <div style={{width:"100%",overflowY:"auto"}}>
        {prompts.slice(0,3).map((el,index)=>{
            return (
                <div key={index+el.title} style={{display:"inline-block",border:'1px solid pink',padding:'12px',margin:"2px",borderRadius:"10px",cursor:"pointer"}}  onClick={()=>{

                    sendMessage(el.prompt,el.title,true)
                }}>
                    {el.title}
                </div>
            )
        })}

    </div>
    <Typography variant="h6">Alternate follow up topics you can try
    
    </Typography>
    <div style={{width:"100%",overflowY:"auto"}}>
        {prompts.slice(3,prompts.length-1).map((el,index)=>{
            return (
                <div key={index+el.title} style={{display:"inline-block",border:'1px solid pink',padding:'12px',margin:"2px",borderRadius:"10px",cursor:"pointer"}}  onClick={()=>{

                    sendMessage(el.prompt,el.title,true)
                }}>
                    {el.title}
                </div>
            )
        })}

    </div>

</SuggestionBox>
  )
}

export default Suggestions