import logo from './logo.svg';
import './App.css';
import LandingPage from './components/landing';

function App() {
  
  return (
    <>
    <LandingPage ></LandingPage>
    </>
  );
}

export default App;
