import React, { useRef, useState, useEffect } from "react";
import { createCompletion } from "../services/openai.service";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import NoMessages from "../images/nomessages.webp";
import { Button, Input, Link, Typography } from "@mui/material";

import Chip from "@mui/material/Chip";
import Suggestions from "./suggestions";
import jwt_decode from "jwt-decode";
import TemporaryDrawer from "./sidebar";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import ChatMessagenger from "./chatMessage";
import { setState, getState } from "../util/localStorage";
import { prompts } from "../util/prompts";
const ParentBox = styled.div`
  width: 100%;
`;
const ChatInputBox = styled.div`
  position: relative;
  width: 100%;
  margin: 30px auto;
`;
const MessagesBox = styled.div`
  width: 95%;
  background: #ffff;
  margin: auto;
  height: 500px;
  /* border: 1px solid pink; */
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track style */
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 3px;
  }

  /* Thumb style */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
    &:hover {
      background-color: #999;
    }
  }
  @media (max-width: 768px) {
    height: 400px;
  }
`;
const ButtonWrapper = styled.div`
  width: 95%;
  margin: 20px auto auto auto;
  padding: 10px 0;
  /* border: 1px solid pink; */
`;

const ActionButton = styled(Button)`
  margin: 5px;
`;
function LandingPage() {
  const [numberOfTimeUsed, setNumberOfTimeUsed] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isUserSignedUp, setIsUserSignedUp] = useState(false);
  const [chatArr, setChatArr] = useState([
    {
      text:"Hi, how are you?",
      direction: "flex-end",
      user: "Hey loveGPT what should i reply to this :(example)",
    },
    {
      text: "I'm doing well, thank you. How about you?",
      direction: "flex-start",
      user: "LoveGPT: Sure, you can reply something like this below :(example)",
    },
  ]);
  const [inputVal, setInputVal] = useState("");
  const [onSuccessBool, setonSuccessBool] = useState(false);
  const SideBarRef = useRef(null);
  const MessageBoxRef = useRef("");
  const LoveGPTstyles = {
    background: "#ff6396",
    color: "white",
  };
  const UserStyles = {
    background: "white",
    color: "black",
  };

  useEffect(() => {
    console.log(numberOfTimeUsed, isUserSignedUp);
    if (numberOfTimeUsed > 3 && !isUserSignedUp) {
      setonSuccessBool(true);
    }
  }, [numberOfTimeUsed]);
  useEffect(() => {
    let clickNumber = getState("numberOfTimesUsed");
    let userDetails = getState("userDetails");
    console.log(userDetails);
    if (userDetails) {
      setIsUserSignedUp(true);
    }
    if (!clickNumber) {
      setState("numberOfTimesUsed", 0);
      setNumberOfTimeUsed(0);
    } else {
      setNumberOfTimeUsed(parseInt(clickNumber));
    }
  }, [onSuccessBool]);
  const sendMessage = async (message, title = "", isFromChat = false) => {
    window.setTimeout(() => {
      MessageBoxRef.current.scrollTop = MessageBoxRef.current.scrollHeight;
    }, 1000);
    try {
      setNumberOfTimeUsed((prev) => prev + 1);
      setChatArr((prev) => [
        ...prev,
        {
          text: title ? title : message,
          direction: "flex-end",
          user: isFromChat
            ? "Hey loveGPT what should i reply to this :"
            : "Hey loveGPT help me to :",
        },
      ]);

      setLoading(true);
      const response = await createCompletion(
        [
          "oh",
          "hmm",
          "ok",
          "okay",
          "mm",
          "umm",
          "erm",
          "umm",
          "uh-huh",
          "i dont know",
          "like",
          "i guess",
          "maybe",
          "i think",
          "you know",
          "so",
        ].includes(message)
          ? `i was chatting with my love and my love said ${message}, what should i say in reply?`
          : message
      );
      console.log(
        "9989",
        response.data.choices[0].text.indexOf("conversation")
      );

      setChatArr((prev) => [
        ...prev,
        {
          text:
            response.data.choices[0].text.indexOf("conversation") === 1
              ? response.data.choices[0].text.substr(14)
              : response.data.choices[0].text,
          direction: "flex-start",
          user: [
            "oh",
            "hmm",
            "ok",
            "okay",
            "mm",
            "umm",
            "erm",
            "umm",
            "uh-huh",
            "i dont know",
            "like",
            "i guess",
            "maybe",
            "i think",
            "you know",
            "so",
          ].includes(message)
            ? "Ahh! we know this pain of short messages"
            : "LoveGPT: Sure, you can reply something like this below :",
        },
      ]);
      console.log("response", response);
      setInputVal("");
      setLoading(false);
      // MessageBoxRef.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "nearest"
      // })
      window.setTimeout(() => {
        MessageBoxRef.current.scrollTop = MessageBoxRef.current.scrollHeight;
        setState("numberOfTimesUsed", numberOfTimeUsed);
      }, 1000);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setChatArr((prev) => [
        ...prev,
        {
          text: "I know I know we are also excited to see you experiment with us, i talked a lot since an hour, taking a quick nap, do come back in a while okay?!",
          direction: "flex-start",
          user: "LoveGPT:",
        },
      ]);
      window.setTimeout(() => {
        MessageBoxRef.current.scrollTop = MessageBoxRef.current.scrollHeight;
      }, 1000);
    }
    // console.log('api',apiResponse.data)
  };
  return (
    <>
      <GoogleOAuthProvider clientId="361879430072-4d5o8ouiq24g1gnq08di4r99k7g64ljr.apps.googleusercontent.com">
        {onSuccessBool ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ mb: 5, fontWeight: "bold" }} varaint="h3">
              {" "}
              Do you like using{" "}
              <span style={{ color: "#ff6396" }}>LoveGPT</span>? please signup /
              login to continue to use it further
            </Typography>
            <GoogleLogin
              onSuccess={async (res) => {
                try {
                  let decodedData = jwt_decode(res.credential);

                  setState("userDetails", decodedData);
                  setonSuccessBool(false);
                  let response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}user`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                      method: "POST",
                      body: JSON.stringify({
                        email: decodedData.email,
                        name: decodedData.name,
                        picture: decodedData.picture,
                      }),
                    }
                  );
                  let responseData = await response.json();
                } catch (e) {
                  console.log(e);
                }
              }}
              onError={(res) => {
                setonSuccessBool(false);
              }}
            />
          </div>
        ) : (
          <ParentBox>
            <TemporaryDrawer ref={SideBarRef} sendMessage={sendMessage} />
            {/* <Typography variant="h4" style={{textAlign:"center"}} color="pink">LoveGPT</Typography> */}
            <MessagesBox ref={MessageBoxRef}>
              {chatArr.length > 0 ? (
                chatArr.map((el) => {
                  return (
                    <ChatMessagenger
                      direction={el.direction}
                      message={el.text}
                      name={el.user}
                    />

                    // <MessageBox
                    //   styles={
                    //     el.user === "loveGpt" ? LoveGPTstyles : UserStyles
                    //   }
                    //   position={el.direction}
                    //   title={el.user === "loveGpt" ? "LoveGPT" : "USER"}
                    //   type="text"
                    //   titleColor={el.user === "loveGpt" && "white"}
                    //   text={el.text}
                    //   date={new Date()}
                    //   replyButton={false}
                    // />
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>
                  <img src={NoMessages} />
                </div>
              )}
            </MessagesBox>
            {/* <Suggestions sendMessage={sendMessage}/> */}
            <ButtonWrapper>
              <Chip
                onClick={() =>
                  sendMessage(
                    "I am a guy who is matched with a girl on a dating app, write an opening flirty ice breaker which could grab her attention for opening",
                    "Start a conversation"
                  )
                }
                variant="contained"
                label="Start conversation"
                sx={{
                  background: "#ff6396",
                  color: "white",
                  "&:hover": {
                    color: "#ff6396",
                    background: "white",
                  },
                }}
              ></Chip>
              
              <Chip
                onClick={() => sendMessage(prompts[0].prompt, prompts[0].title)}
                variant="contained"
                label="Ask out for coffee"
                sx={{
                  background: "#ff6396",
                  color: "white",
                  "&:hover": {
                    color: "#ff6396",
                    background: "white",
                  },
                }}
              ></Chip>
              <Chip
                onClick={() => SideBarRef.current.log()}
                variant="contained"
                label="Others.."
                sx={{
                  background: "#ff6396",
                  color: "white",
                  "&:hover": {
                    color: "#ff6396",
                    background: "white",
                  },
                }}
              ></Chip>
            </ButtonWrapper>
            <ChatInputBox>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // border: "1px solid pink",
                  width: "95%",
                  margin: "auto",
                }}
              >
                <Input
                  fullWidth
                  onChange={(e) => {
                    setInputVal(e.target.value);
                  }}
                  value={inputVal}
                  placeholder="Type here what did she said..."
                />
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    disabled={!inputVal}
                    onClick={() => sendMessage(inputVal, "", true)}
                    variant="contained"
                  >
                    Send
                  </Button>
                )}
              </div>
            </ChatInputBox>
            {/* <div style={{ textAlign: "center" }}>
              <Link
                href="https://forms.gle/uVFCEetM6FkaBPmj6"
                style={{ color: "#ff6396" }}
              >
                help us to become better
              </Link>
            </div> */}
          </ParentBox>
        )}
      </GoogleOAuthProvider>
    </>
  );
}

export default LandingPage;
