import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import FeedbackIcon from "@mui/icons-material/Feedback";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { prompts } from "../util/prompts";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../images/logo.PNG";
const MenuTitle = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;
const TemporaryDrawer = React.forwardRef(({ sendMessage }, ref) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  React.useImperativeHandle(ref, () => ({
    log() {
      setState({ ...state, ["right"]: true });
    },
  }));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box

    sx={{
      width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      background:"#FF597B",
      color:"#ffff"
      /* Set the width and height of the scrollbar */
      
    }}



      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List  sx={{background:"#FF597B"}}>
        <ListItem key={"help us to become better"} disablePadding>
          <ListItemButton
            onClick={() => {
              window.location.href = "https://forms.gle/uVFCEetM6FkaBPmj6";
            }}
          >
            <ListItemIcon>
              <FeedbackIcon style={{color:"white"}}/>
            </ListItemIcon>
            <ListItemText primary={"help us to become better"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Typography
        sx={{ mt: 0.5, ml: 2 }}
        color="#f2f2f2"
        display="block"
        variant="caption"
      >
        Or try one of these:
      </Typography>
      <List  sx={{background:"#FF597B"}}>
        {prompts.slice(0, 3).map((el, index) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                sendMessage(el.prompt, el.title);
              }}
            >
              <ListItemText primary={el.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography
        sx={{ mt: 0.5, ml: 2 }}
        color="#f2f2f2"
        display="block"
        variant="caption"
      >
        Alternate follow up topics you can try:
      </Typography>

      <List  sx={{background:"#FF597B"}}>
        {prompts.slice(3, prompts.length).map((el, index) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                sendMessage(el.prompt, el.title);
              }}
            >
              <ListItemText primary={el.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuTitle>Suggestions</MenuTitle> <MenuIcon color="secondary" />
            </Button>
            <Typography
              sx={{ mt: 0.5, ml: 2, padding: "10px" }}
              color="#FF597B"
              display="block"
              variant="h5"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <span>
                  LoveGPT<sub style={{ fontSize: "10px" }}>BETA</sub>{" "}
                </span>
                <span>
                  <img src={Logo} width="50" />
                </span>
              </div>
            </Typography>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
           
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
});

export default TemporaryDrawer;
