import React from "react";
import styled from "@emotion/styled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
const ChatMessageParent = styled.div`
  display: flex;
  align-items: center;
`;
const CopyToClipBoard = styled(Chip)`
  /* position: absolute;
  bottom: -35px;
  right: 0; */
`;
const Chat = styled.div`
  /* max-width: 70px; */
  padding: 10px;
  /* border: 2px solid black; */
  display: block;
  position: relative;
  width:100%;
  /* border-radius: 10px 10px 10px 10px; */
  
  position: relative;
`;
const Messagenger = styled.div`
  font-weight: bold;
  /* position: absolute;
  top: -20px;
  left: 0; */
`;
function ChatMessage({ direction, message, name }) {
  const [copied,setCopied] = React.useState(false)
  return (
    <ChatMessageParent style={{ justifyContent: direction }}>
      <Chat
        
        style={
          direction === "flex-end"
            ? {  background: "#ff6399" ,color: "white",}
            : {
               
                color: "black",
                background: "white",
              }
        }
      >
        <Messagenger
          style={direction === "flex-start" ? { color: "black" } : {color: "white"}}
        >
          {name}
          {direction === "flex-start" && (
        <CopyToClipBoard
          label={<ContentCopyIcon />}
          title={"copy"}
          onClick={() => {
            navigator.clipboard.writeText(message)
           
          }}
        ></CopyToClipBoard>
      )}
        </Messagenger>
       <Typography>
        
         {message.split('\n').map(str => <p>{str}</p>)}
        </Typography>
      
      </Chat>
    </ChatMessageParent>
  );
}

export default ChatMessage;
